import React, {FC, useState} from 'react';
import st from './styles.module.scss'
import SectionContainer from "../../../sharedComponent/containers/SectionContainer/SectionContainer";
import Treangle from "../../../assets/images/gradients/Treangle";
import Wade from '../../../assets/images/Details/Wade.png'
import photo2_2 from '../../../assets/images/homePage/photo2_2.png'
import photo2_3 from '../../../assets/images/homePage/photo2_3.png'
import {Button} from '@bonuz/bonuz-ui';
import BigGradient from "../../../assets/images/gradients/BigGradient";
import CardRaise2 from "../../../sharedComponent/cards/CardRaise2/CardRaise2";


const HPSection3: FC = () => {
    const cards = [
        {
            image: Wade,
            name: 'Heiko Lochmann',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2_2,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2_3,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2_2,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2_3,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
    ]
    const [limit, setLimit] = useState(3)

    return (
        <SectionContainer className={st.launchpad}>
            <div className={st.launchpadTrLeft}>
                <Treangle/>
            </div>
            <div className={st.launchpadBig}>
                <BigGradient/>
            </div>


            <div className={st.launchpadMain}>
                <h2 className={st.launchpadTitle}>
                    Celebrities Projects
                </h2>
                <p className={st.launchpadSubTitle}>
                    We bring new technologies to our community
                </p>

                <div className={st.launchpadCards}>
                    {
                        cards.map(({image, name, totalRaise, personalAlocation}, i) => {
                                if (i >= limit) return
                                return <CardRaise2
                                    image={image}
                                    name={name}
                                    totalRaise={totalRaise}
                                    personalAlocation={personalAlocation}
                                    key={i + name}
                                    id={i}
                                />
                            }
                        )

                    }
                </div>
                <div className={st.launchpadBtnCon} style={{display: limit === cards.length ? 'none' : ''}}>
                    <div className={st.launchpadBtn}>
                        <Button secondary onClick={() => {
                            setLimit(cards.length)
                        }}>
                            Show all projects
                        </Button>
                    </div>
                    <div className={st.launchpadBtnCon1}> </div>
                    <div className={st.launchpadBtnCon1}> </div>
                    <div className={st.launchpadBtnCon1}> </div>

                </div>

            </div>
        </SectionContainer>
    );
};

export default HPSection3;