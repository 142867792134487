import React, {FC} from 'react';
import st from './index.module.scss'
import {Title} from "@bonuz/bonuz-ui";
import {Link} from "react-router-dom";
import ArrowLink from "../../../assets/icons/arrowLink";

interface IProps {
    text: string,
    date: string
}

const OneNews: FC<IProps> = ({text, date}) => {
    return (
        <div className={st.containerNews}>
            <div>
                <p>
                    {text}
                </p>
                <time>
                    {date}
                </time>
            </div>
            <Link className={st.containerNewsBtn} to='#'>
                <ArrowLink/>
            </Link>
        </div>
    );
};

export default OneNews;