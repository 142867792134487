import React ,{ FC }  from 'react';

const Medium: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.4042 3.76289L20 2.31658V2H14.4717L10.5317 11.2905L6.04917 2H0.2525V2.31658L2.11667 4.44263C2.29833 4.59974 2.39333 4.82947 2.36917 5.06079V13.4158C2.42667 13.7166 2.32333 14.0261 2.1 14.2447L0 16.6558V16.9684H5.95417V16.6518L3.85417 14.2447C3.62667 14.0253 3.51917 13.7213 3.565 13.4158V6.18895L8.79167 16.9724H9.39917L13.8933 6.18895V14.7792C13.8933 15.0058 13.8933 15.0524 13.7367 15.2008L12.12 16.6826V17H19.9633V16.6834L18.405 15.2379C18.2683 15.14 18.1975 14.9758 18.2267 14.8163V4.18447C18.1975 4.02421 18.2675 3.86 18.4042 3.76289V3.76289Z" fill="white"/>
        </svg>
    );
};

export default Medium;