import React ,{ FC }  from 'react';

const Telegram: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.84784 12.5451L7.517 17.0122C7.99034 17.0122 8.19534 16.817 8.44118 16.5827L10.6604 14.5467L15.2588 17.7794C16.1022 18.2306 16.6964 17.993 16.9239 17.0346L19.9423 3.45712L19.9431 3.45632C20.2106 2.25952 19.4923 1.79153 18.6706 2.08512L0.928504 8.60589C-0.282359 9.05709 -0.264025 9.70508 0.722666 9.99868L5.25861 11.3531L15.7947 5.02431C16.2905 4.70911 16.7414 4.88351 16.3705 5.19871L7.84784 12.5451Z" fill="white"/>
        </svg>
    );
};

export default Telegram;