import React, {FC} from 'react';
import s from './styles.module.scss'
import {NavLink} from "react-router-dom";

const IconBlueContainer:FC = ({children}) => {
    return (
        <NavLink to='/'  className={s.container} >
            <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_34_4298)">
                    <path d="M22 0L11.0765 6.38045L-1.17689e-07 12.5L0.153068 25L-1.17689e-07 37.5L11.0765 43.6195L22 50L32.9235 43.6195L44 37.5L43.8469 25L44 12.5L32.9235 6.38045L22 0Z" fill="#23158E"/>
                </g>
                <defs>
                    <filter id="filter0_b_34_4298" x="-14" y="-14" width="72" height="78" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImage" stdDeviation="7"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_34_4298"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_34_4298" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <div className={s.containerChild}>
                {children}
            </div>
        </NavLink>

    );
};

export default IconBlueContainer;