import React, {FC} from 'react';
import st from './styles.module.scss';

type TRaise = {
    totalRaise: string
    personalAlocation: string
}
const Raise: FC<TRaise> = ({totalRaise, personalAlocation}) => {
    return (
        <div className={st.cardBg2Raise}>
            <div className={st.cardBg2RaiseTotal}>

                <div className={st.cardBg2RaiseTitle}>
                    Total raise
                </div>
                <div className={st.cardBg2RaiseSubTitle}>
                    ${totalRaise}
                </div>

            </div>
            <div className={st.cardBg2RaisePerson}>

                <div className={st.cardBg2RaiseTitle}>
                    Personal Alocation
                </div>
                <div className={st.cardBg2RaiseSubTitle}>
                    ${personalAlocation}
                </div>

            </div>
        </div>
    );
};

export default Raise;