import React, {FC} from 'react';
import st from './styles.module.scss'
import BlueHps1 from "../../../assets/images/gradients/blueHPS1";
import SectionContainer from "../../../sharedComponent/containers/SectionContainer/SectionContainer";
import BigGradient from "../../../assets/images/gradients/BigGradient";
import PlusesGradient from "../../../assets/images/gradients/PlusesGradient";
import DoubleBgCardRotate from "../../../sharedComponent/cards/DoubleBgCardRotate/DoubleBgCardRotate";

import Left from '../../../assets/images/homePage/left.png'
import Right from '../../../assets/images/homePage/right.png'


const HpSection1: FC = () => {
    const info = [
        {title: '324', subtitle: ' Celebrities started work with Bonuz Market'},
        {title: '10k', subtitle: ' Created tokens for celebrities community'},
        {title: '100k', subtitle: ' More active users on the Bonuz community'},
    ]
    return (
        <SectionContainer className={st.launchpad}>

            <div className={`${st.launchpadGradient} ${st.launchpadGradientCardLeft}`}>
                <BlueHps1/>
            </div>
            <div className={`${st.launchpadGradient} ${st.launchpadGradientBig}`}>
                <BigGradient/>
            </div>
            <div className={`${st.launchpadGradient} ${st.launchpadGradientPlusLeft}`}>
                <PlusesGradient/>
            </div>


            <div className={st.launchpadCardLeft}>
                <DoubleBgCardRotate title='Julius Dein'
                                    subtitle='34,500 $BONUZ 11 $MENDE'
                                    image={Left}
                                    left={true}
                />
            </div>

            <div className={st.launchpadCardRight}>
                <DoubleBgCardRotate
                    title='Lukas Reiger'
                    subtitle='34,500 $BONUZ 11 $MENDE'
                    image={Right}
                    right={true}
                />
            </div>

            <div className={`${st.launchpadGradient} ${st.launchpadGradientPlusRight}`}>
                <PlusesGradient/>
            </div>
            <div className={`${st.launchpadGradient} ${st.launchpadGradientCardRight}`}>
                <BlueHps1/>
            </div>

            <div className={st.launchpadMain}>
                <h1 className={st.launchpadTitle}>
                    Celebrity & Brand Token Launchpad
                </h1>

                <p className={st.launchpadSubTitle}>
                    A revolutionary ecosystem for creators, celebrities,
                    brands and other people of fame to engage with fans like never before.
                    Privat Channels, NFT’s, Merch, Metaverse and more!
                </p>

                <div className={st.launchpadInfo}>
                    {
                        info.map(({title, subtitle}, i) =>
                            <div className={st.launchpadInfoBlock} key={i + title}>
                                <h2 className={st.launchpadInfoBlockTitle}>
                                    {title}
                                </h2>
                                <p className={st.launchpadInfoBlockSubTitle}>
                                    {subtitle}
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>

        </SectionContainer>
    );
};

export default HpSection1;