import React, {FC} from 'react';

const ArrowLink: FC = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.11336 4.66678C5.98919 4.79169 5.91949 4.96066 5.91949 5.13678C5.91949 5.31291 5.98919 5.48187 6.11336 5.60678L8.47336 8.00012L6.11336 10.3601C5.98919 10.485 5.91949 10.654 5.91949 10.8301C5.91949 11.0062 5.98919 11.1752 6.11336 11.3001C6.17533 11.3626 6.24907 11.4122 6.33031 11.446C6.41154 11.4799 6.49868 11.4973 6.58669 11.4973C6.6747 11.4973 6.76183 11.4799 6.84307 11.446C6.92431 11.4122 6.99805 11.3626 7.06002 11.3001L9.88669 8.47345C9.94917 8.41147 9.99877 8.33774 10.0326 8.2565C10.0665 8.17526 10.0839 8.08812 10.0839 8.00012C10.0839 7.91211 10.0665 7.82497 10.0326 7.74373C9.99877 7.66249 9.94917 7.58876 9.88669 7.52678L7.06002 4.66678C6.99805 4.6043 6.92431 4.5547 6.84307 4.52085C6.76183 4.48701 6.6747 4.46958 6.58669 4.46958C6.49868 4.46958 6.41154 4.48701 6.33031 4.52085C6.24907 4.5547 6.17533 4.6043 6.11336 4.66678Z" fill="#FFFBFF"/>
        </svg>
    );
};

export default ArrowLink;