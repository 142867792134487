import React, {FC} from 'react';
import {ICardRaise} from "../../../interface/cards";
import st from './styles.module.scss';
import Raise from "../cardParts/Raise/Raise";
import Links from "../cardParts/Links/Links";

const CardRaise1: FC<ICardRaise> = (
    {
        image,
        name,
        totalRaise,
        personalAlocation
    }
) => {

    return (
        <div className={st.card}>
            <div className={st.cardImg} style={{
                background: `url(${image}) center/cover no-repeat`
            }}> </div>
            <div className={st.cardMain}>
                <div className={st.cardBg1}>
                    <button className={st.cardBg1Btn}>
                        Coming Soon
                    </button>
                    <div className={st.cardBg1Title}>

                        <div className={st.cardBg1TitleName}>
                            {name}
                        </div>

                        <div className={st.cardBg1TitleType}>
                            $BCMC
                        </div>


                    </div>
                </div>

                <div className={st.cardBg2}>

                    <div className={st.cardBg2Before}>
                        <Links/>
                    </div>


                    <div className={st.cardBg2ProgressLine}>
                        <div className={st.cardBg2ProgressLineActive}>

                        </div>
                    </div>

                    <Raise totalRaise={totalRaise}
                           personalAlocation={personalAlocation}/>

                </div>
            </div>


        </div>
    );
};

export default CardRaise1;