import React, {FC, useState} from 'react';
import st from './styles.module.scss'
import SectionContainer from "../../../sharedComponent/containers/SectionContainer/SectionContainer";
import Treangle from "../../../assets/images/gradients/Treangle";
import CardRaise1 from 'sharedComponent/cards/CardRaise1/CardRaise1';
import Heiko from '../../../assets/images/Details/Heiko.png'
import photo2 from '../../../assets/images/homePage/photo2.png'
import photo3 from '../../../assets/images/homePage/photo3.png'
import {Button} from '@bonuz/bonuz-ui';
import BigGradient from "../../../assets/images/gradients/BigGradient";


const HPSection2: FC = () => {
    const cards = [
        {
            image: Heiko,
            name: 'Heiko Lochmann',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo3,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: Heiko,
            name: 'Heiko Lochmann',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo3,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo2,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
        {
            image: photo3,
            name: 'Wade Warren',
            totalRaise: '203 000',
            personalAlocation: '542 000',
        },
    ]
    const [limit, setLimit] = useState(3)

    return (
        <SectionContainer className={st.launchpad}>
            <div className={st.launchpadTrLeft}>
                <Treangle/>
            </div>
            <div className={st.launchpadTrRight}>
                <Treangle/>
            </div>
            <div className={st.launchpadBig}>
                <BigGradient/>
            </div>


            <div className={st.launchpadMain}>
                <h2 className={st.launchpadTitle}>
                    Upcoming Celebrity
                </h2>
                <p className={st.launchpadSubTitle}>
                    Owning a one-time acquired token allows a lifetime
                </p>

                <div className={st.launchpadCards}>
                    {
                        cards.map(({image, name, totalRaise, personalAlocation}, i) => {
                                if (i >= limit) return
                                return <CardRaise1
                                    image={image}
                                    name={name}
                                    totalRaise={totalRaise}
                                    personalAlocation={personalAlocation}
                                    key={i + name}
                                />
                            }
                        )

                    }
                </div>
                <div style={{display: limit === cards.length ? 'none' : ''}}>
                    <Button secondary onClick={() => {
                        setLimit(cards.length)
                    }}>
                        Show all
                    </Button>
                </div>


            </div>

        </SectionContainer>
    );
};

export default HPSection2;