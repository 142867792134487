import React from 'react';

const Treangle = () => {
    return (
        <svg width="626" height="560" viewBox="0 0 626 560" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <path d="M464.482 27.2328L389.567 46.8701L314.437 64.9624L285.499 130.096L254.887 194.85L300.864 240.346L345.383 287.009L420.298 267.372L495.428 249.279L524.366 184.146L554.978 119.391L509.001 73.8951L464.482 27.2328Z" fill="url(#paint0_linear_68_4550)"/>
                <path d="M505.357 272.499L430.442 292.137L355.312 310.229L326.374 375.362L295.762 440.117L341.739 485.613L386.258 532.275L461.173 512.638L536.303 494.546L565.241 429.412L595.853 364.658L549.876 319.162L505.357 272.499Z" fill="url(#paint1_linear_68_4550)"/>
                <path d="M239.646 213.304L164.731 232.941L89.601 251.034L60.663 316.167L30.0514 380.922L76.0284 426.418L120.547 473.08L195.462 453.443L270.592 435.351L299.53 370.217L330.142 305.463L284.165 259.966L239.646 213.304Z" fill="url(#paint2_linear_68_4550)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_68_4550" x1="537.258" y1="-93.0001" x2="425.044" y2="220.716" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.15"/>
                    <stop offset="1" stopColor="white" stopOpacity="0.03"/>
                </linearGradient>
                <linearGradient id="paint1_linear_68_4550" x1="503.544" y1="259.913" x2="394.383" y2="425.571" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.12"/>
                    <stop offset="1" stopColor="white" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="paint2_linear_68_4550" x1="255.449" y1="186.09" x2="132.365" y2="369.304" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.08"/>
                    <stop offset="1" stopColor="#250B71" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Treangle;