import React, {FC} from 'react';

type IProps = {
    width?: string,
    height?: string
}

const BigGradient:FC<IProps> = ({width='1798', height='1401'}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 1798 1401" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" filter="url(#filter0_f_68_4523)">
                <path d="M797.973 607.018C937.291 643.423 1335.27 931.302 1344.5 977C1497.66 1098.66 1267.75 718.576 1306 607.018V450L551.877 401.037C532.483 399.778 513.909 409.038 503.241 425.283L400 582.5C414.608 611.302 658.655 570.613 797.973 607.018Z" fill="#FF00F5"/>
            </g>
            <defs>
                <filter id="filter0_f_68_4523" x="0" y="0.923828" width="1797.74" height="1399.81" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="200" result="effect1_foregroundBlur_68_4523"/>
                </filter>
            </defs>
        </svg>
    );
};

export default BigGradient;