import React, { FC } from "react";
import Header from "../components/Header";

const ErrorLayout: FC = ({ children }) => {
  return (
    <>
      <Header error />
      <main>{children}</main>
    </>
  );
};

export default ErrorLayout;
