import React, {FC} from 'react';
import st from './index.module.scss'
import {Input, Button, Title} from '@bonuz/bonuz-ui'
import OneNews from "./oneNews/oneNews";
import Treangle from "../../assets/images/gradients/Treangle";
import BigGradient from "../../assets/images/gradients/BigGradient";
import SectionContainer from "../../sharedComponent/containers/SectionContainer/SectionContainer";


const News: FC = () => {

    const dataNews = [
        {
            text: 'We create new BlockChain Compnay in 2020 see more details in blog. That big start for Team.',
            date: '10 Oct. 2020'
        },
        {
            text: 'We create new BlockChain Compnay in 2020 see more details in blog. That big start for Team.',
            date: '10 Oct. 2020'
        },
        {
            text: 'We create new BlockChain Compnay in 2020 see more details in blog. That big start for Team.',
            date: '10 Oct. 2020'
        }
    ]

    return (
        <SectionContainer>
            <div className={st.container}>
                <div className={st.feedBack}>
                    <Title>Hello.</Title>
                    <p>Let’s get in touch.</p>
                    <form className={st.feedBackForm}>
                        <Input
                            placeholder='Name'
                        />
                        <Input
                            placeholder='Email'
                        />
                        <Input
                            placeholder='Message'
                        />
                        <Button secondary>
                            Get In Touch
                        </Button>
                    </form>
                    <div className={st.figureLeft}>
                        <Treangle/>
                    </div>
                </div>
                <div className={st.news}>
                    <Title>News</Title>
                    {dataNews.map(({text, date}, idx) => (
                        <OneNews
                            text={text}
                            date={date}
                            key={idx}
                        />
                    ))}
                    <div className={st.figureSecond}>
                        <Treangle/>
                    </div>
                    <div className={st.figureFigure}>
                        <BigGradient
                            width='1000'
                        />
                    </div>
                </div>
            </div>
        </SectionContainer>
    );
};

export default News;