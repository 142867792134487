import ErrorLayout from 'layouts/ErrorLayout';
import React from 'react';
import st from './styles.module.scss'

const ErrorPage = () => {
    return (
        <ErrorLayout>
            <section className={st.error}>
                error
            </section>
        </ErrorLayout>
    );
};

export default ErrorPage;