import ErrorPage from 'pages/ErrorPage/ErrorPage';
import {FC} from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";

const AppRouter:FC = () =>(
    <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="*" element={<ErrorPage />} />
    </Routes>
    )

export default AppRouter;