import React, {FC} from 'react';
import st from './index.module.scss'

type TSectionContainer={
    className?:string
}

const SectionContainer:FC<TSectionContainer> = ({children,className = ''}) => {
    return (
        <section className={`${st.container} ${className && className}`}>
            <div className="_container">
                {children}
            </div>
        </section>
    );
};

export default SectionContainer;