import React, {FC} from 'react';
import {IDoubleBgCardRotate} from "../../../interface/cards";
import st from './styles.module.scss';


const DoubleBgCardRotate: FC<IDoubleBgCardRotate> = (
    {
        image,
        title,
        subtitle,
        right = false,
        left = false,
    }
) => {

    return (
        <div className={`${st.card} ${right?st.right:''} ${left?st.left:''}`}
            >
            <div className={st.cardBg1}
                 style={{
                     background: `url(${image}) center/cover no-repeat`
                 }}
            > </div>
            <div className={st.cardBg2}> </div>

            <div className={st.cardFlag}>
                <div className={st.cardFlagTitle}>
                    {title}
                </div>
                <div className={st.cardFlagSubTitle}>
                    {subtitle}
                </div>
            </div>

        </div>
    );
};

export default DoubleBgCardRotate;