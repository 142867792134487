import React, {FC} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";

const AppLayout: FC  = ({children}) => {
    return (
        <>
            <Header />
            <main >
                {children}
            </main>
            <Footer />
        </>
    );
};

export default AppLayout;