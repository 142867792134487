import React, {FC} from 'react';
import {ICardRaise} from "../../../interface/cards";
import st from './styles.module.scss';
import Raise from "../cardParts/Raise/Raise";
import Links from "../cardParts/Links/Links";
import PhotoContainer from "../cardParts/PhotoContainer/PhotoContainer";


const CardRaise2: FC<ICardRaise> = (
    {
        image,
        name,
        totalRaise,
        personalAlocation,
        id
    }
) => {

    return (
        <div className={st.card}>
            <div className={st.cardImg}>
                <PhotoContainer id={id} image={image}/>
            </div>
            <div className={st.cardMain}>

                <div className={st.cardBg1Title}>

                    <div className={st.cardBg1TitleName}>
                        {name}
                    </div>

                    <div className={st.cardBg1TitleType}>
                        $BCMC
                    </div>
                </div>


                <Links/>

                <Raise totalRaise={totalRaise}
                       personalAlocation={personalAlocation}/>

            </div>


        </div>
    );
};

export default CardRaise2;