import React, { FC } from 'react';
import { navigation } from '../../../mocks/headerNav';
import s from './styles.module.scss'

const Nav: FC = () => {
  return (
    <nav className={s.nav}>
      {navigation.map(i => (
        <a
          key={i?.link}
          href={i?.link}
          className={s.link}
        >
          {i?.title}
        </a>
      ))}
    </nav>
  );
};

export default Nav;
