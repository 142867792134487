import classNames from 'classnames';
import { Button } from '@bonuz/bonuz-ui';
import React, { FC, useState } from 'react';
import plates from '../../assets/bg/Plates.svg';
import SearchIcon from '../../assets/icons/SearchIcon';
import Logo from '../Logo';
import Nav from './Nav';
import s from './styles.module.scss'
import Hamburger from "../Hamburger";

const Header: FC<{error?:boolean}> = ({error = false}) => {
    const [mobMenuIsVisible, setMobMenuIsVisible] = useState<boolean>(false)

    const toggleMenu = () => {
        setMobMenuIsVisible(prev => !prev)
    }

    return (
        <header className={s.header}>
            <div className={s.headerWrapper}>
                <Hamburger
                    isActive={mobMenuIsVisible}
                    onClick={toggleMenu}
                />

                <div className={s.logoWrapper}>
                    <Logo />
                </div>
                {!error &&
                <div className={s.navWrapper}>
                    <Nav />
                </div>
                }

                <div className={s.wrapper}>
                    {!error &&
                    <div className={s.search}>
                        <SearchIcon />
                    </div>
                    }
                    <div className={s.btnWrapper}>
                        <Button
                           secondary
                        >
                           Start Work with Bonuz
                        </Button> 
                    </div>
                </div>
            </div>

            <div
                className={classNames(s.mobMenu, {
                    [s.active]: mobMenuIsVisible
                })}
                style={{alignItems:error?'center':'',justifyContent:error?'center':''}}
            >
                {!error && <Nav />}

                <Button>Start Work with Bonuz</Button>
                <div className={s.mobMenuBg}>
                    <img src={`${plates}`} alt="Plates"/>
                </div>
            </div>
        </header>
    );
};

export default Header;
