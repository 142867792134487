import React, { FC } from 'react';
import { Link } from "react-router-dom";
import s from './styles.module.scss'
import LogoIcon from '../../assets/icons/LogoIcon';

const Logo: FC = () => {
  return (
      <Link className={s.logo} to='/'>
          <LogoIcon />
      </Link>

  );
};

export default Logo;
