import React, {FC} from 'react';
import st from './style.module.scss';
import IconBlueContainer from "../../../iconContainers/IconBlueContainer/IconBlueContainer";
import Telegram from "../../../../assets/icons/Telegram";
import Twitter from "../../../../assets/icons/Twitter";
import Medium from "../../../../assets/icons/Medium";
import Instagram from "../../../../assets/icons/Instagram";

const Links:FC = () => {
    return (
        <div className={st.cardBg2Links}>
            <IconBlueContainer>
                <Telegram/>
            </IconBlueContainer>

            <IconBlueContainer>
                <Twitter/>
            </IconBlueContainer>

            <IconBlueContainer>
                <Medium/>
            </IconBlueContainer>

            <IconBlueContainer>
                <Instagram/>
            </IconBlueContainer>
        </div>
    );
};

export default Links;