import React, {FC} from 'react';

const FooterM: FC = () => {
    return (
        <svg width="57" height="64" viewBox="0 0 57 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_5114_372)">
                <path d="M14.849 9.03308L14.8387 9.03902L14.8283 9.04472L1.00738 16.5894L1.19822 31.9876L1.19837 32L1.19822 32.0124L1.00738 47.4106L14.8283 54.9553L14.8387 54.961L14.849 54.9669L28.5 62.8454L42.151 54.9669L42.1613 54.961L42.1717 54.9553L55.9926 47.4106L55.8018 32.0124L55.8016 32L55.8018 31.9876L55.9926 16.5894L42.1717 9.04472L42.1613 9.03902L42.151 9.03308L28.5 1.15459L14.849 9.03308Z" stroke="url(#paint0_linear_5114_372)" strokeWidth="2"/>
            </g>
            <path d="M36.4042 25.7629L38 24.3166V24H32.4717L28.5317 33.2905L24.0492 24H18.2525V24.3166L20.1167 26.4426C20.2983 26.5997 20.3933 26.8295 20.3692 27.0608V35.4158C20.4267 35.7166 20.3233 36.0261 20.1 36.2447L18 38.6558V38.9684H23.9542V38.6518L21.8542 36.2447C21.6267 36.0253 21.5192 35.7213 21.565 35.4158V28.1889L26.7917 38.9724H27.3992L31.8933 28.1889V36.7792C31.8933 37.0058 31.8933 37.0524 31.7367 37.2008L30.12 38.6826V39H37.9633V38.6834L36.405 37.2379C36.2683 37.14 36.1975 36.9758 36.2267 36.8163V26.1845C36.1975 26.0242 36.2675 25.86 36.4042 25.7629Z" fill="white"/>
            <defs>
                <filter id="filter0_b_5114_372" x="-14" y="-14" width="85" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="7"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5114_372"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5114_372" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_5114_372" x1="-2.14371" y1="31.544" x2="57.3966" y2="30.7153" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CE09FF"/>
                    <stop offset="1" stopColor="#FFA34E"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FooterM;