import React, {FC} from 'react';
import {Link} from 'react-router-dom'
import st from './styles.module.scss'
import LogoFooter from "../../assets/images/LogoFooter";
import FooterTelegram from "../../assets/icons/footer_telegram";
import FooterM from "../../assets/icons/footer_m";
import FooterTwiter from "../../assets/icons/footer_twiter";

const Footer: FC = () => {
    return (
        <footer className={st.footer}>
            <div className={st.footerContainer}>
                <LogoFooter
                    externalClass={st.logoFooter}
                />
                <h4>
                    Glad you visited our website. In case you found a bug or have an suggestion, then kindly reach out.
                    This is a community project and we are happy to listen to your feedback.
                </h4>
                <nav>
                    <ul className={st.social}>
                        <li>
                            <Link to='/telegram'>
                                <FooterTelegram/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/medium'>
                                <FooterM/>
                            </Link>
                        </li>
                        <li>
                            <Link to='/twitter'>
                                <FooterTwiter />
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;
