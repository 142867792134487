import React, {FC} from 'react';

const BlueHps1:FC = () => {
    return (
        <svg width="303" height="425" viewBox="0 0 303 425" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.787354" y="0.582031" width="302" height="424" rx="8" fill="url(#paint0_linear_68_4463)"/>
            <defs>
                <linearGradient id="paint0_linear_68_4463" x1="151.787" y1="0.582031" x2="151.787" y2="424.582" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1E1375"/>
                    <stop offset="1" stopColor="#170B71"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BlueHps1;