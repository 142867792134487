import React ,{ FC }  from 'react';

const Twitter: FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 3.89415C19.2562 4.21538 18.4637 4.42831 17.6375 4.53169C18.4875 4.032 19.1363 3.24677 19.4413 2.30031C18.6488 2.76554 17.7737 3.09415 16.8412 3.27754C16.0887 2.48862 15.0162 2 13.8462 2C11.5762 2 9.74875 3.81415 9.74875 6.03815C9.74875 6.35815 9.77625 6.66585 9.84375 6.95877C6.435 6.79508 3.41875 5.18646 1.3925 2.736C1.03875 3.34031 0.83125 4.032 0.83125 4.77662C0.83125 6.17477 1.5625 7.41415 2.6525 8.13169C1.99375 8.11938 1.3475 7.93108 0.8 7.63446C0.8 7.64677 0.8 7.66277 0.8 7.67877C0.8 9.64062 2.22125 11.2702 4.085 11.6455C3.75125 11.7354 3.3875 11.7785 3.01 11.7785C2.7475 11.7785 2.4825 11.7637 2.23375 11.7095C2.765 13.3083 4.2725 14.4837 6.065 14.5218C4.67 15.5963 2.89875 16.2437 0.98125 16.2437C0.645 16.2437 0.3225 16.2289 0 16.1883C1.81625 17.3415 3.96875 18 6.29 18C13.835 18 17.96 11.8462 17.96 6.512C17.96 6.33354 17.9537 6.16123 17.945 5.99015C18.7587 5.42154 19.4425 4.71138 20 3.89415Z" fill="white"/>
        </svg>
    );
};

export default Twitter;