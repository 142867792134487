import React, {FC} from 'react';
import s from './styles.module.scss';
import classNames from "classnames";

interface IProps {
    isActive: boolean,
    onClick: () => void
}

const Hamburger: FC<IProps> = ({ isActive, onClick }) => {
    return (
        <div
            className={classNames(s.hamburger, {
                [s.active]: isActive
            })}
            onClick={onClick}
        >
            <span className={s.line} />
            <span className={s.line} />
            <span className={s.line} />
        </div>
    );
};

export default Hamburger;
