import {FC} from 'react';
import HpSection1 from "../../components/HomePageSections/HPSection1/HPSection1";
import HPSection2 from "../../components/HomePageSections/HPSection2/HPSection2";
import News from "../../components/News/news";
import HPSection3 from "../../components/HomePageSections/HPSection3/HPSection3";
import AppLayout from "../../layouts/AppLayout";

const HomePage: FC = () => {
    return (
        <AppLayout>
            <HpSection1/>
            <HPSection2/>
            <HPSection3/>
            <News/>
        </AppLayout>
    );
};

export default HomePage;