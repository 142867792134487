import React, {FC} from 'react';

const FooterTelegram: FC = () => {
    return (
        <svg width="57" height="64" viewBox="0 0 57 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_5114_367)">
                <path d="M14.849 9.03308L14.8387 9.03902L14.8283 9.04472L1.00738 16.5894L1.19822 31.9876L1.19837 32L1.19822 32.0124L1.00738 47.4106L14.8283 54.9553L14.8387 54.961L14.849 54.9669L28.5 62.8454L42.151 54.9669L42.1613 54.961L42.1717 54.9553L55.9926 47.4106L55.8018 32.0124L55.8016 32L55.8018 31.9876L55.9926 16.5894L42.1717 9.04472L42.1613 9.03902L42.151 9.03308L28.5 1.15459L14.849 9.03308Z" stroke="url(#paint0_linear_5114_367)" strokeWidth="2"/>
            </g>
            <path d="M25.8478 34.5451L25.517 39.0122C25.9903 39.0122 26.1953 38.817 26.4412 38.5827L28.6604 36.5467L33.2588 39.7794C34.1022 40.2306 34.6964 39.993 34.9239 39.0346L37.9423 25.4571L37.9431 25.4563C38.2106 24.2595 37.4923 23.7915 36.6706 24.0851L18.9285 30.6059C17.7176 31.0571 17.736 31.7051 18.7227 31.9987L23.2586 33.3531L33.7947 27.0243C34.2905 26.7091 34.7414 26.8835 34.3705 27.1987L25.8478 34.5451Z" fill="white"/>
            <defs>
                <filter id="filter0_b_5114_367" x="-14" y="-14" width="85" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="7"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5114_367"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5114_367" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_5114_367" x1="-2.14371" y1="31.544" x2="57.3966" y2="30.7153" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CE09FF"/>
                    <stop offset="1" stopColor="#FFA34E"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FooterTelegram;