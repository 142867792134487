type NavigationItem = {
  link: string;
  title: string;
};

export const navigation: NavigationItem[] = [
  {
    link: '#stats',
    title: 'Stats',
  },
  {
    link: '#upcoming',
    title: 'Upcoming',
  },
  {
    link: '#celebrities',
    title: 'Celebrities',
  },
  {
    link: '#getintouch',
    title: 'Get in Touch',
  }
];
