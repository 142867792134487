import React, {FC} from 'react';

const FooterTwiter: FC = () => {
    return (
        <svg width="57" height="64" viewBox="0 0 57 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_5114_377)">
                <path d="M14.849 9.03308L14.8387 9.03902L14.8283 9.04472L1.00738 16.5894L1.19822 31.9876L1.19837 32L1.19822 32.0124L1.00738 47.4106L14.8283 54.9553L14.8387 54.961L14.849 54.9669L28.5 62.8454L42.151 54.9669L42.1613 54.961L42.1717 54.9553L55.9926 47.4106L55.8018 32.0124L55.8016 32L55.8018 31.9876L55.9926 16.5894L42.1717 9.04472L42.1613 9.03902L42.151 9.03308L28.5 1.15459L14.849 9.03308Z" stroke="url(#paint0_linear_5114_377)" strokeWidth="2"/>
            </g>
            <path d="M38 25.8942C37.2562 26.2154 36.4637 26.4283 35.6375 26.5317C36.4875 26.032 37.1363 25.2468 37.4413 24.3003C36.6488 24.7655 35.7737 25.0942 34.8412 25.2775C34.0887 24.4886 33.0162 24 31.8462 24C29.5762 24 27.7487 25.8142 27.7487 28.0382C27.7487 28.3582 27.7762 28.6658 27.8438 28.9588C24.435 28.7951 21.4188 27.1865 19.3925 24.736C19.0387 25.3403 18.8313 26.032 18.8313 26.7766C18.8313 28.1748 19.5625 29.4142 20.6525 30.1317C19.9937 30.1194 19.3475 29.9311 18.8 29.6345C18.8 29.6468 18.8 29.6628 18.8 29.6788C18.8 31.6406 20.2212 33.2702 22.085 33.6455C21.7512 33.7354 21.3875 33.7785 21.01 33.7785C20.7475 33.7785 20.4825 33.7637 20.2337 33.7095C20.765 35.3083 22.2725 36.4837 24.065 36.5218C22.67 37.5963 20.8988 38.2437 18.9813 38.2437C18.645 38.2437 18.3225 38.2289 18 38.1883C19.8162 39.3415 21.9688 40 24.29 40C31.835 40 35.96 33.8462 35.96 28.512C35.96 28.3335 35.9537 28.1612 35.945 27.9902C36.7587 27.4215 37.4425 26.7114 38 25.8942Z" fill="white"/>
            <defs>
                <filter id="filter0_b_5114_377" x="-14" y="-14" width="85" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="7"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5114_377"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5114_377" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_5114_377" x1="-2.14371" y1="31.544" x2="57.3966" y2="30.7153" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CE09FF"/>
                    <stop offset="1" stopColor="#FFA34E"/>
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FooterTwiter;